.autocomplete-dropdown {
    position: absolute; /* Position the dropdown relative to its parent */
    z-index: 1000; /* Ensure the dropdown appears above other elements */
    background-color: white; /* White background for the dropdown */
    border: 1px solid #ccc; /* Border for visual separation */
    border-radius: 4px; /* Slight rounding of the corners */
    /* max-height: 200px; Limit the height of the dropdown */
    overflow-y: auto; /* Enable vertical scrolling if there are many items */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for depth */
    padding: 5px; /* Padding inside the dropdown */
    width: 100%; /* Ensure the dropdown matches the input's width */
  }
  
  .autocomplete-dropdown div {
    padding: 10px; /* Padding for each suggestion */
    cursor: pointer; /* Change cursor to pointer on hover */
  }
  
  .autocomplete-dropdown div:hover {
    background-color: #f1f1f1; /* Light gray background on hover */
  }