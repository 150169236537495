.card:hover{
    /* transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06); */
    background-color: #f5f5f5;
  }

@media (max-width: 992px) {
  .mt-30-lg {
    margin-top: 30px;
  }

  .mt-40-lg {
    margin-top: 40px;
  }

  .mt-50-lg {
    margin-top: 50px;
  }

  .mt-55-lg {
    margin-top: 55px;
  }

  .mt-60-lg {
    margin-top: 60px;
  }
}

@media (min-width: 992px) {
  .w-lg-75 {
    width: 75%;
  }
}

.card-img-top-3x4 {
  object-fit: cover; /* Ensures the image covers the container, cropping excess parts if necessary */
  aspect-ratio: 3 / 4; /* Maintains a 3:4 aspect ratio */
  width: 100%; /* Ensures the image fills the width of the container */
  height: auto; /* Adjusts the height to maintain the aspect ratio */
  border-radius: 0;
}

.main-image {
  width: 100%;
  cursor: pointer;
}

.thumbnail-square {
  object-fit: cover;
  width: 80px;
  height: 80px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  border: 1px solid #ddd;
}

.thumbnail-square:hover {
  border-color: #555;
}


/* Dropzone */
.dropzone {
  border: 2px dashed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  transition: border 0.3s ease;
  position: relative;
  overflow: auto;
}

.dropzone-file-list {
  margin-top: 10px;
  max-height: 100px; /* Adjust based on available space */
  overflow-y: auto;
  width: 100%;
}

.dropzone-file-list p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  font-size: 0.9em;
  line-height: 1.2;
  word-wrap: break-word;
}

.dropzone-remove-file-button {
  background: none;
  border: none;
  /* color: #dc3545; */
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
}

.dropzone-remove-file-button:hover {
  color: #c82333;
}

.favourite-toggler {
  cursor: pointer;
  user-select: none;
}

.favourite-toggler .fa-heart {
  transition: transform 0.2s ease;
}
.favourite-toggler:hover .fa-heart {
  transform: scale(1.2);
}

/* Donation percentage slider */
.slider-wrapper {
  position: relative;
  width: 100%;
}

/* .form-range {
  width: 100%;
  margin: 20px 0;
} */

.slider-value {
  position: absolute;
  top: -30px;
  transform: translateX(-25%);
  background: #05445e;
  color: white;
  padding: 2px 6px;
  font-size: 14px;
  font-weight: bold;
}

.graduation-marks {
  width: calc(100% - 15px);
  display: flex;
  justify-content: space-between;
  margin-top: -26px;
}

/* .graduation-marks span {
  font-size: 12px;
} */

