.image-zoom-container {
    position: relative;
    display: inline-block;
}

.lens {
    position: absolute;
    border: 1px solid #000;
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.4);
}

.zoomed-image {
    position: absolute;
    top: 0;
    left: 105%;
    border: 1px solid #000;
    background-repeat: no-repeat;
    z-index: 10;
}
  