.cell-text-truncate {
    display: inline-block;
    max-width: 40rem;
  }

  @media (max-width: 992px) {
    .cell-text-truncate {
        display: inline-block;
        max-width: 10rem;
      }
  }