@media (min-width: 992px) {
    .search-school {
      width: 560px;
    }
}

.custom-divider {
  border-color: #ccc;
  border-width: 1px;
}

  